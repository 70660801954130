/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// @ts-check

/**
 * @param {string} name cookie key
 * @returns {string | null}
 */
function getCookie(name) {
  const parts = document.cookie.split(/;\s*/)
  for (let i = 0; i < parts.length; i++) {
    const [k, v] = parts[i].split('=')

    if (k === name) return v
  }

  return null
}

/**
 *
 * @param {string} name cookie key
 * @param {number | string} value cookie value
 * @param {number} days maxAge days
 * @returns {void}
 */
function setCookie(name, value, days) {
  const oneDay = 24 * 60 * 60
  const maxAge = oneDay * days
  const expires = new Date(Date.now() + maxAge * 1000)

  document.cookie = `${name}=${value};max-age=${maxAge};expires=${expires};path=/;domain=.3dcat.live`
}

/** @type {import("gatsby").GatsbyBrowser['onClientEntry']} */
export const onClientEntry = async () => {
  const blockComefrom = getCookie('blockComefrom')

  if (!blockComefrom || Date.now() > Number(blockComefrom)) {
    let referrer = document.referrer || document.URL
    const search = location.search

    if (document.referrer && search.includes('source=')) {
      if (referrer.includes('&')) {
        referrer = `${referrer}&${search.slice(1)}`
      } else {
        referrer += search
      }
    }

    // 记录保持 7 天
    setCookie('comefrom', referrer, 7)
    // 记录 24 小时内不可覆盖
    setCookie('blockComefrom', new Date().setDate(new Date().getDate() + 1), 1)
  }
}
